<template lang="pug">
.cm-sidebar(:class="{ 'cm-sidebar--expanded': expanded }")
  .cm-sidebar__wrapper
    slot(name="header")
    .cm-sidebar__preloader(v-if="loading" data-test="cm-preloader")
      v-skeleton-loader( type="text" v-for="loader in [1,2,3,4,5,6,7,8,9,10]" :key="loader").cm-sidebar__preloader-item
    groupedList.cm-sidebar__body
      template(v-for="(month, index) in list")
        groupedListTitle(:key="index" v-if="month !== 'onlyName'")
          template(v-slot:title="groupedListTitlte" ) {{ month.label }}
          groupedListItem(
            v-if="month !== 'onlyName'"
            v-for="item in month.dates"
            :key="`${item.ID}`"
            data-test="cm-course"
            @click.native="onClick($event, item)"
          )
            CMCourseCard(
              :item="item"
              :multiple="isMultipleMode"
              :active="isActive(item)"
              @updated="needToUpdate"
            )
              | {{ item.date | DOWDutchDDD }} {{ item.date }} {{ item.name }}
  div.cm-sidebar__expand(@click="toggleSidebar")
    v-icon(color="white") {{ expanded ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
</template>

<script>
import CMDateHashMap, {CMDateHashMapSelectableDecorator} from "../classes/CMDateHashMap"
import {createNamespacedHelpers} from 'vuex'
import {VIOLET_COLOR} from '../core/cm-const'

const {mapState} = createNamespacedHelpers('cm')
import {CMDateSelectable} from "@/app/admin/modules/course_managment/classes/CMDate";
import CMCourseCard from "@/app/admin/modules/course_managment/components/q-management/CMCourseCard.vue";

export default {
  inject: ['svc'],

  name: 'CmSidebarBody',

  props: {
    courseList: Array,
    isMultipleMode: Boolean,
    loading: Boolean,
    selector: Object,
  },

  data: () => ({
    VIOLET_COLOR,
    expanded: false
  }),

  computed: {
    ...mapState({
      activeCourses: state => state.activeCourses,
    }),

    list() {
      if (!this.courseList) return {}
      return new CMDateHashMapSelectableDecorator(new CMDateHashMap(this.courseList)).convert()
    }
  },

  methods: {
    onClick(event, item) {
      if (!event.shiftKey) {
        this.$emit('click:select', item)
      } else {
        if (!this.isMultipleMode) return
        const listOfCourses = Object.values(this.list).map(list => list.dates).flat().map(item => new CMDateSelectable(item))
        const firstCourseInList = listOfCourses.find(course => this.activeCourses.map(c => c.ID).includes(course.ID));
        const firstCourseIndex = this._getIndexByID(listOfCourses, firstCourseInList.ID)
        const lastCourseIndex = this._getIndexByID(listOfCourses, item.ID)

        let arrayOfSelectedCourses;
        // for selecting from bottom to top
        if (firstCourseIndex === lastCourseIndex) {
          const currentLastIndex = this._getIndexByID(listOfCourses, this.activeCourses[0].ID)
          arrayOfSelectedCourses = listOfCourses.slice(firstCourseIndex, currentLastIndex + 1);
        } else {
          // for selecting from top to bottom
          arrayOfSelectedCourses = listOfCourses.slice(firstCourseIndex, lastCourseIndex + 1);
        }
        this.$emit('selectWithShift', arrayOfSelectedCourses)
      }
    },

    _getIndexByID(list, ID) {
      return list.map(item => item.ID).indexOf(ID)
    },

    needToUpdate() {
      this.$emit('needToUpdateData')
    },
    isActive(item) {
      return this.activeCourses.map(course => course.ID).includes(item.ID)
    },

    toggleSidebar() {
      this.expanded = !this.expanded;
    }
  },

  components: {
    CMCourseCard,
    groupedList: () => import('@/components/global/grouped-list/GroupedList.vue'),
    groupedListTitle: () => import('@/components/global/grouped-list/GroupedListTitle.vue'),
    groupedListItem: () => import('@/components/global/grouped-list/GroupedListItem.vue'),
    courseCounts: () => import('./СMCourseCounts.vue'),
  }
}
</script>

<style lang="scss" scoped>

.cm-sidebar {
  width: 190px;
  min-width: 190px;
  height: calc(100vh - 135px);
  border-right: 1px solid $border-color;
  position: relative;
  padding-top: 64px;
  @media screen and (max-width: 767px) {
    width: 0;
    min-width: 0;
    background-color: #fff;
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 5px 0 14px -3px rgba(0, 0, 0, 0.5);
    &--expanded {
      width: 190px !important;
      min-width: 190px !important;
    }
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  &__preloader {
    margin-top: 5px;
    padding-left: 24px;
    padding-right: 24px;

    &-item {
      padding: 8px 0;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__title {
    @extend %title-subsection;
  }

  &__expand {
    position: absolute;
    top: 0;
    right: -32px;
    background-color: $primary-color;
    color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}

.mode-switch {
  position: absolute;
  top: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

::v-deep .v-input--selection-controls.v-input .v-label {
  font-size: 12px;
}

.course-date {
  word-break: break-all;
  margin-right: 20px;
}
</style>
