<template lang="pug">
  cm-sidebar(
    :isMultipleMode="isMultipleMode"
    :loading="loading"
    :courseList="courseList"
    :selector="selector"
    @needToUpdateData="loadData"
    @click:select="selectDate"
    @selectWithShift="selectDates"
  )
    template(v-slot:header)
      div.cm-header-cell.cm-spacing.pr-2.cm-sidebar__header
        .d-flex.justify-space-between.align-center.full-width.mb-30
          p.cm-sidebar__title.mb-0 Choose date
            v-switch.mode-switch(
              :input-value="isMultipleMode"
              @change="changeMode"
              :disabled="loading"
              :color="VIOLET_COLOR"
              label="Multi Courses"
            )
          showingProvider(v-slot="showingProvider")
            examDataStore(v-slot="examStore" :key="`${showingProvider.showing}showingrovider`")
              formErrorsProvider(v-slot="errors")
                createCourseExam(
                  v-slot="courseExam"
                  :formErrors="errors.formErrors"
                  @create="loadData"
                  @end="showingProvider.toggle"
                )
                  courseExamValidator(
                    :formErrors="errors.formErrors"
                    v-slot="courseExamValidator"
                    @validated="courseExam.checkValidatorBeforeSend"
                  )
                    createCourseProvider(
                      v-slot="course"
                      :formErrors="errors.formErrors"
                      @create="loadData"
                      @end="showingProvider.updateShowing(false)"
                    )
                      courseValidator(:formErrors="errors.formErrors" @validated="course.checkValidatorBeforeSend" v-slot="validator")
                        v-dialog(
                          max-width="400px"
                          :value="showingProvider.showing"
                          @input="showingProvider.updateShowing"
                          :key="`${showingProvider.showing}showingrovider`"
                        )
                          template(v-slot:activator="{on}")
                            v-btn(icon v-on="on" color="primary" x-small)
                              v-icon(size="24") mdi-plus
                          template(v-slot:default="dialogDefault")
                            modal-body(
                              :key="`${showingProvider.showing}course`"
                              :editData="course.formData"
                              :courseLocation="$route.query.city"
                              :formErrors="errors.formErrors"
                              :showing="showingProvider.showing"
                              @back="showingProvider.toggle"
                              @update-form="examStore.updateFormData"
                              title="Create course"
                            )
                              v-divider
                              div.pt-2.pb-3.d-flex.justify-end
                                v-switch(v-model="examStore.formData.rangeMode" label="Range mode").range-switch
                              h2.cm-sidebar__title Create exam
                              exam-body(
                                :formData="examStore.formData"
                                :formErrors="errors.formErrors"
                                :showing="showingProvider.showing"
                                isCreateExam
                              )
                              template(v-slot:actions="modalProps")
                                v-spacer
                                v-btn(outlined width="80" @click="showingProvider.toggle").btn-common Cancel
                                v-btn(
                                  color="primary"
                                  width="80"
                                  :loading="course.processing || courseExam.processing"
                                  @click="validator.validationBeforeSend({data: modalProps.formData, examData: examStore.formData})"
                                  data-test="cm-create-submit"
                                ) Add

                                v-btn(
                                  color="primary"
                                  :loading="course.processing || courseExam.processing"
                                  @click="courseExamValidator.validationBeforeSend({data: modalProps.formData, examData: examStore.formData})"
                                  data-test="cm-create-submit"
                                ) Add with exam
</template>

<script>
import ModalBody from './cm_course/CMCourseBody.vue'
import { createNamespacedHelpers } from 'vuex'
import { VIOLET_COLOR } from '../core/cm-const'

const { mapState, mapMutations, mapGetters, mapActions } = createNamespacedHelpers('cm')
import cmSidebar from "./CMSidebar.vue"
import cmSidebarMixin from "../core/mixins/cmSidebar.mixin"
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import { CANDIDATE_PAGE } from '../../candidates/core/candidates-const'

export default {
  name: 'CmSelectorSidebar',

  inject: ['svc'],

  mixins: [cmSidebarMixin, featureFlagsMixin],

  data: () => ({
    VIOLET_COLOR
  }),

  computed: {
    ...mapState({
      isMultipleMode: state => state.isMultipleMode
    }),
  },

  created() {
    this.initMode()
  },

  mounted() {
    this.initOnMounted()
  },

  methods: {
    ...mapMutations({
      setMultipleMode: 'setMultipleMode',
      setSingleMode: 'setSingleMode',
    }),

    ...mapActions({
      courseListDetailed: 'courseListDetailed'
    }),

    async initOnMounted() {
      this.$emit('mounted')
      this.initStorage()
      await this.loadData()
    },

    async initMode() {
      this.$route.query.course_id && this.$route.query.course_id instanceof Array ? this.setMultipleMode() : this.setSingleMode()
      if(this.isMultipleMode && !this.$route.query.page_name) {
        await this.$router.replace({
          ...this.$route.query,
          page_name: CANDIDATE_PAGE.TOB_CBR_RESERVE_QUEUE
        })
      }

    },

    async loadData() {
      await this.loadList()
    },

    async changeMode() {
      if (this.loading) return
      this.isMultipleMode ? this.setSingleMode() : this.setMultipleMode()
      this.setCourses([])
      await this.setDateQuery()
      this.informAboutChangedDate()
    },

    async loadList() {
      try {
        this.loading = true
        const query = {...this.$route.query}
        delete query.license_type;
        delete query.size;
        await this.courseListDetailed({ query })
        this.$emit('coursesLoaded', this.list)
      } catch (error) {
        console.log(error)
      }
      finally {
        this.loading = false
      }
    },

    async selectDates(items) {
      items.forEach(item => {
        this.selectCourse(item)
      })
      await this.setDateQuery()
      this.informAboutChangedDate()
    },

    async selectDate(item) {
      if (this.loading) return
      if (item.active) {
        if (this.isMultipleMode) this.unSelectCourse(item)
        else return
      } else {
        this.isMultipleMode ? this.selectCourse(item) : this.setCourses([item])
      }
      await this.setDateQuery()
      this.informAboutChangedDate()
    },
  },

  watch: {
    // '$route.query.license_type'() {
    //   this.initOnMounted()
    // }
  },

  components: {
    cmSidebar: cmSidebar,
    modalBody: ModalBody,
    createCourseProvider: () => import('./cm_course/CMCreateCourseProvider.vue'),
    courseValidator: () => import('./cm_course/CMCourseValidator.vue'),
    examDataStore: () => import('./cm_exam/CMExamDataStore.vue'),
    examBody: () => import('./cm_exam/CmExamBody.vue'),
    configCreateProvider: () => import('./cm_exam/CMExamAddProvider.vue'),
    showingProvider: () => import('@/components/renderless/ShowingProvider.vue'),
    createCourseExam: () => import('./cm_course/CMCreateCourseExam.vue'),
    formErrorsProvider: () => import('@/components/renderless/FormErrors.vue'),
    courseExamValidator: () => import('./cm_course/CMCourseExamValidator.vue'),
  }
}
</script>

<style lang="scss" scoped>
.cm-sidebar {
  width: 190px;
  min-width: 190px;
  height: calc(100vh - 135px);
  border-right: 1px solid $border-color;
  position: relative;
  padding-top: 64px;
  @media screen and (max-width: 767px) {
    width: 0;
    min-width: 0;
    background-color: #fff;
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 5px 0 14px -3px rgba(0, 0, 0, 0.5);
    &--expanded {
      width: 190px !important;
      min-width: 190px !important;
    }
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  &__preloader {
    margin-top: 5px;
    padding-left: 24px;
    padding-right: 24px;

    &-item {
      padding: 8px 0;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__title {
    @extend %title-subsection;
  }

  &__expand {
    position: absolute;
    top: 0;
    right: -32px;
    background-color: $primary-color;
    color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}

.mode-switch {
  position: absolute;
  top: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

::v-deep .v-input--selection-controls.v-input .v-label {
  font-size: 12px;
}

.course-date {
  word-break: break-all;
}
</style>
