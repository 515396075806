import { render, staticRenderFns } from "./CMSidebar.vue?vue&type=template&id=0b005642&scoped=true&lang=pug&"
import script from "./CMSidebar.vue?vue&type=script&lang=js&"
export * from "./CMSidebar.vue?vue&type=script&lang=js&"
import style0 from "./CMSidebar.vue?vue&type=style&index=0&id=0b005642&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b005642",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VIcon,VSkeletonLoader})
