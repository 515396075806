import Selector from '@/util/selector';
import CMDateHashMap, { CMDateHashMapSelectableDecorator } from "../../classes/CMDateHashMap";
import { PerPageStorage } from '@/models/perPageStorage';
import uniq from '@/util/uniqInArray.js';
import { getMultipleQueryValueWithNumber } from '@/util';
import { createNamespacedHelpers } from 'vuex';
import { CANDIDATE_PAGE } from '../../../candidates/core/candidates-const';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('cm');

export default {
  data: () => ({
    storage: null,
    loading: true,
    selector: new Selector(),
  }),

  computed: {
    ...mapState({
      courseList: state => state.courseListDetailed,
      activeCourses: state => state.activeCourses,
    }),

    list() {
      if (!this.courseList) return {};
      return new CMDateHashMapSelectableDecorator(new CMDateHashMap(this.courseList)).convert();
    }
  },

  watch: {
    courseList() {
      this.setActiveDate();
    }
  },

  methods: {
    ...mapMutations({
      setCourses: 'setCourses',
      unSelectCourse: 'unSelectCourse',
      selectCourse: 'selectCourse',
      clearAll: 'clearAll',
    }),

    ...mapActions({
      courseListDetailed: 'courseListDetailed'
    }),

    initStorage() {
      this.storage = new PerPageStorage();
    },

    setActiveDate() {
      if (!this.$route.query.course_id || !this.courseList) return;
      let courses = [...this.courseList];
      const coursesIds = getMultipleQueryValueWithNumber(this.$route.query.course_id);

      const activeCourses = typeof this.$route.query.course_id === 'string' ?
        courses.filter(course => course.ID === +this.$route.query.course_id) :
        courses.filter(course => coursesIds.includes(course.ID));

      if (!activeCourses) return;

      let fields = uniq(activeCourses.map(course => {
        return course.date ? new CMDateHashMap().getYearMonth(course.date) : 'onlyName';
      }));
      let datesInList = this._getActiveDates(fields, coursesIds);
      if (uniq(datesInList).length) {
        this.setCourses(datesInList);
        this.informAboutChangedDate();
      }
    },

    _getActiveDates(months, coursesIds) {
      let datesInList = [];
      months.forEach(field => {
        this.list[field].dates.forEach(date => {
          if (coursesIds.includes(date.ID)) {
            datesInList.push(date);
          }
        });
      });
      return datesInList;
    },

    informAboutChangedDate() {
      this.$emit('click:course');
    },

    async setDateQuery() {
      let coursesIds = this.activeCourses.map(item => item.ID);
      const pageName = this.$route.query.page_name ? this.$route.query.page_name : CANDIDATE_PAGE.RESERVED_ALL;
      await this.$router.replace({query: { ...this.$route.query, course_id: coursesIds, page: 1, page_name: pageName, size: this.storage.getPerPageCount() }})
    }
  }
};
