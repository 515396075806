import CMGroupedMonth from "./CMGroupedMonth"
import { CMDateSelectable } from "./CMDate"

export default class CMDateHashMap {
  constructor(list) {
    this.list = list
  }

  convert() {
    let hashMap = {}
    this.list.forEach(item => {
      if(!item.date) {
        if('onlyName' in hashMap) hashMap.onlyName.dates.push(item)
        else hashMap['onlyName'] = {
          label: 'Names',
          dates: [item]
        }
        return
      }
      let date = this.getYearMonth(item.date)
      
      if(date in hashMap) {
        hashMap[date].dates.push(item)
      } else hashMap[date] = new CMGroupedMonth(item)
    })
    return hashMap
  }

  getYearMonth(date) {
    let [day, month, year] = date.split('-')
    return [year, month].join('-')
  }
}

export class CMDateHashMapSelectableDecorator {
  constructor(hashMap) {
    this.hashMap = hashMap
  }

  convert() {
    let data = this.hashMap.convert()
    Object.values(data).forEach(month => {
      month.dates = month.dates.map(date => new CMDateSelectable(date))
    })
    return data
  }
}